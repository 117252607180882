// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/ContactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/HomeTemplate.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-info-template-js": () => import("./../../../src/templates/InfoTemplate.js" /* webpackChunkName: "component---src-templates-info-template-js" */),
  "component---src-templates-privacy-template-js": () => import("./../../../src/templates/PrivacyTemplate.js" /* webpackChunkName: "component---src-templates-privacy-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/ProjectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

